import React, { useEffect, useRef } from 'react';
import './index.css';
import { widget } from '../../charting_library/';
import Datafeed from './api/datafeed';

export function TVChartContainer(props) {
	const { stockDetails, symbol, interval, libraryPath, fullscreen, autosize } = props;
	const ref = useRef(null);
	let tvWidget = useRef(null);

	// const saveChartTemplate = () => {
	// 	tvWidget.current.save((chartTemplate) => {
	// 		localStorage.setItem('chartTemplate', JSON.stringify(chartTemplate));
	// 	});
	// };

	// const loadChartTemplate = () => {
	// 	const chartTemplate = localStorage.getItem('chartTemplate');
	// 	if (chartTemplate) {
	// 		tvWidget.current.load(JSON.parse(chartTemplate));
	// 	}
	// };

	useEffect(() => {
		const currentTime = new Date();
		const noonTime = new Date();
		noonTime.setHours(14, 0, 0, 0);
		let timePeriod = "0915-1530";
		// let timePeriod = "0915-1400";
		// if(currentTime > noonTime){
		// 	timePeriod = "0915-1530";
		// } 
		const widgetOptions = {
			debug: false,
			symbol: stockDetails?.S || symbol,
			datafeed: new Datafeed(stockDetails),
			interval:interval,
			container: ref.current,
			library_path: libraryPath,
			timezone: 'Asia/Kolkata',
			session: timePeriod,
			locale: 'en',
			// disabled_features: ['use_localstorage_for_settings', 'header_symbol_search', 'header_compare'],
			disabled_features: ['header_symbol_search', 'header_compare'],
			// disabled_features : ['header_symbol_search', 'header_compare',"link_to_tradingview", "use_localstorage_for_settings", "header_interval_dialog_button", "symbol_info", "countdown", "chart_property_page_trading", "header_fullscreen_button"],
			enabledFeatures: true,
			custom_css_url: "/assets/chart-style.css",
			fullscreen:fullscreen,
			autosize:autosize,
		};

		tvWidget.current = new widget(widgetOptions);
		tvWidget.current.onChartReady(() => {
			tvWidget.current.headerReady().then(() => {

			});
		});

		// tvWidget.current.onChartReady(() => {
		// 	tvWidget.current.headerReady().then(() => {
		// 		loadChartTemplate();
		// 		const button = tvWidget.current.createButton();
		// 		button.setAttribute("title", "Save Template");
		// 		button.textContent = "Save Template";
		// 		button.addEventListener("click", saveChartTemplate);
		// 		button.style.cursor = "pointer";
		// 	});
		// });

		return () => {
			if (tvWidget.current !== null) {
				tvWidget.current.remove();
				tvWidget.current = null;
			}
		};
	}, [stockDetails, symbol, interval, libraryPath, fullscreen, autosize]);

	return <div ref={ref} className={'TVChartContainer'} />;
}

TVChartContainer.defaultProps = {
	symbol: 'No Symbol',
	interval: '1',
	libraryPath: '/charting_library/',
	fullscreen: true,
	autosize: true,
};
