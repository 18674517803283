// import { apiCall } from "app/services/apiService/api";

import axios from "axios";

// Makes requests to CryptoCompare API
export async function makeApiRequest(parameters, authToken, chartUrl) {
	try {
		try {
			const result = await axios.post(chartUrl, parameters,
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + authToken,
					},
				}
			);
			if (parameters?.body?.minute_request) {
				if (result.status == 200 && typeof result.data.status != 'undefined' && result.data.status == "success" && result.data.data != [] && result.data.data.length > 0) {
					return result.data.data;
				} else {
					return [];
				}
			}
			try {
				if (result.status == 200 && typeof result.data.status != 'undefined' && result.data.status == "success") {
					const response = JSON.parse(result.data.data);
	
					if (typeof response?.data !== "undefined" && response?.data != [] && response?.data?.length > 0) {
						return response?.data;
					} else if (response != [] && response.length > 0) {
						return response;
					}
					return [];
	
				} else {
					return [];
				}
			} catch (error) {
				if (result.status == 200 && typeof result.data.status != 'undefined' && result.data.status == "success" && result.data.data != [] && result.data.data.length > 0) {
					return result.data.data;
				} else {
					return [];
				}		
			}
			
		} catch (error) {
			console.error('Error:', error);
		}

	} catch (error) {
		throw new Error(`CryptoCompare request error: ${error.status}`);
	}
}

// Generates a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
	const short = `${fromSymbol}/${toSymbol}`;
	return {
		short,
		full: `${exchange}:${short}`,
	};
}

// Returns all parts of the symbol
export function parseFullSymbol(fullSymbol) {
	const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
	if (!match) {
		return null;
	}

	return {
		exchange: match[1],
		fromSymbol: match[2],
		toSymbol: match[3],
	};
}
