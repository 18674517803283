import { makeApiRequest } from './helpers.js';
import { subscribeOnStream, unsubscribeFromStream } from './streaming.js';

const lastBarsCache = new Map();
const configurationData = {
	// supported_resolutions: ['1', '5', '5D', '1W', '1M', '6M'],
	supported_resolutions: ["1","2","3","4","10","15","30","1H","2H","3H","1D","5D","1W","1M","6M"],
	intraday_multipliers: ['1'],
};


export default function Datafeed(stockDetails) {


	const onReady = (callback) => {
		setTimeout(() => callback(configurationData));
	};
	const resolveSymbol = async (symbolName, onSymbolResolvedCallback, onResolveErrorCallback, extension) => {
		const symbolItem = {
			full_name: stockDetails.S,
			description: stockDetails.SD,
			exchange: stockDetails.marketExchangeName,
			marketSegment: stockDetails.MS,
			token: stockDetails.T,
			user_id: stockDetails.userId,
			api_exchange: stockDetails.marketExchange,
			chart_url: stockDetails.chartUrl,
			auth_token: stockDetails.authToken,
			MS: stockDetails.MS,
			S: stockDetails.S,
		};

		if (!symbolItem) {
			onResolveErrorCallback('Symbol not found');
			return;
		}

		const currentTime = new Date();
		const noonTime = new Date();
		noonTime.setHours(14, 0, 0, 0);
		let timePeriod = "0915-1530";
		// let timePeriod = "0915-1400";
		// if (currentTime > noonTime) {
		// 	timePeriod = "0915-1530";
		// }
		const symbolInfo = {
			ticker: symbolItem.full_name,
			name: symbolItem.full_name,
			// description: symbolItem.description,
			description: symbolItem.full_name,
			session: timePeriod,
			timezone: 'Asia/Kolkata',
			exchange: symbolItem.exchange,
			minmov: 1,
			marketSegment: symbolItem.marketSegment,
			token: symbolItem.token,
			pricescale: 100,
			// has_intraday: true,
			has_intraday: !0,
            type: "stock",
			has_no_volume: false,
			has_weekly_and_monthly: false,
			supported_resolutions: configurationData.supported_resolutions,
			volume_precision: 2,
			data_status: 'streaming',
			// intraday_multipliers: configurationData.intraday_multipliers,
			user_id: symbolItem.user_id,
			api_exchange: symbolItem.api_exchange,
			temp_symbol: symbolItem.S,
			temp_ms: symbolItem.MS,
			auth_token: symbolItem.auth_token,
			chart_url: symbolItem.chart_url,

		};

		onSymbolResolvedCallback(symbolInfo);
	};

	const getBars = async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
		const { from, to, firstDataRequest } = periodParams;
		// let s = symbolInfo.ticker;
		// let l = false; 
		// let h = "day";
		// if (s) {
		// 	if (-1 === resolution.indexOf("D") && parseInt(resolution) > 1) {
		// 		h = resolution + "minute";
		// 	} else if (-1 === resolution.indexOf("D") && 1 === parseInt(resolution)) {
		// 		h = "minute";
		// 	} else if (120 === parseInt(resolution)) {
		// 		h = "2hour";
		// 	} else if (180 === parseInt(resolution)) {
		// 		h = "3hour";
		// 	} else if ("1W" === resolution) {
		// 		h = "week";
		// 	} else if ("1M" === resolution) {
		// 		h = "day";
		// 	}
		// 	// console.log("h", h);
		// }

		try {	
			const startDate = new Date(from * 1000);
			const toDate = new Date(to * 1000);
			var date = startDate.getFullYear() + '-' + String(startDate.getMonth() + 1).padStart(2, '0') + '-' + String(startDate.getDate()).padStart(2, '0') + ' ' + String(startDate.getHours()).padStart(2, '0') + ':' + String(startDate.getMinutes()).padStart(2, '0') + ':' + String(startDate.getSeconds()).padStart(2, '0');

			var date2 = toDate.getFullYear() + '-' + String(toDate.getMonth() + 1).padStart(2, '0') + '-' + String(toDate.getDate()).padStart(2, '0') + ' ' + String(toDate.getHours()).padStart(2, '0') + ':' + String(toDate.getMinutes()).padStart(2, '0') + ':' + String(toDate.getSeconds()).padStart(2, '0');

			const chartDataParameters = {
				taskcode: '100',
				entitycode: '591',
				body: {
					EX: stockDetails.marketExchange == "NFO" ? "NSE_FO" : stockDetails.marketExchange,
					T: stockDetails.T,
					D: '1-MIN',
					SDT: date,
					EDT: date2,
					S:stockDetails.S,
					MS:stockDetails.MS,
					minute_request:false
				},
			};

			const data = await makeApiRequest(chartDataParameters, stockDetails.authToken, stockDetails.chartUrl);

			if (data.length === 0) {
				onHistoryCallback([], { noData: true });
				return;
			}

			const bars = [];
			let lastBar;


			data.forEach((bar) => {
				const barDate = bar.DT;
				const [datePart, timePart] = barDate.split(' ');
				const [year, month, day] = datePart.split('-');
				const [hour, minute, second] = timePart.split(':');
				const dateObj = new Date(year, month - 1, day, hour, minute, second);
				const formattedDate = dateObj.getTime();
				if (formattedDate >= startDate.getTime() && formattedDate <= toDate.getTime()) {
					const newBar = {
						time: formattedDate,
						low: bar.L,
						high: bar.H,
						open: bar.O,
						close: bar.C,
						volume:Number(bar.V)
					};

					bars.push(newBar); 
					lastBar = newBar;
				}
			});

			// if (bars.length > 0) {
			// 	const firstBarTime = bars[0].time;
			// 	const lastBarTime = bars[bars.length - 1].time;

			// 	if (firstBarTime < from * 1000) {
			// 		bars.unshift({
			// 			time: from * 1000,
			// 			low: bars[0].low,
			// 			high: bars[0].high,
			// 			open: bars[0].open,
			// 			close: bars[0].close,
			// 			volume: bars[0].volume,
			// 		});
			// 	}

			// 	if (lastBarTime > to * 1000) {
			// 		bars.push({
			// 			time: to * 1000,
			// 			low: bars[bars.length - 1].low,
			// 			high: bars[bars.length - 1].high,
			// 			open: bars[bars.length - 1].open,
			// 			close: bars[bars.length - 1].close,
			// 			volume: bars[bars.length - 1].volume,
			// 		});
			// 	}
			// }

			if (firstDataRequest) {
				const currentTime = new Date();
				const lastTempBar = bars[bars.length - 1]; 
				const seconds = currentTime.getSeconds();
				const tempTime = lastTempBar.time - seconds * 1000; 
				lastTempBar.tempTime = tempTime;
				lastBarsCache.set(symbolInfo.full_name, {
					...lastTempBar,
				});
			}
			// lastBarsCache.set(symbolInfo.full_name, lastBar);

			onHistoryCallback(bars, { noData: false });

		} catch (error) {
			onErrorCallback(error);
		}
	};

	const subscribeBars = (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
		subscribeOnStream(
			symbolInfo,
			resolution,
			onRealtimeCallback,
			subscriberUID,
			onResetCacheNeededCallback,
			lastBarsCache.get(symbolInfo.full_name)
		);
	};

	const unsubscribeBars = (subscriberUID) => {
		unsubscribeFromStream(subscriberUID);
	};

	return {
		onReady,
		resolveSymbol,
		getBars,
		subscribeBars,
		unsubscribeBars,
	};
}
