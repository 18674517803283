import React, { useEffect, useState } from 'react';
import './App.css';
import { TVChartContainer } from './components/TVChartContainer/index';


const App = () => {
	const [stockDetails, setStockDetails] = useState("");
	useEffect(() => {
		const getQueryParams = () => {
			const queryParams = new URLSearchParams(window.location.search);
			const paramValue = queryParams.get('parameter');

			if (paramValue) {
				try {
					const decodedParamValue = atob(paramValue);
					setStockDetails(JSON.parse(decodedParamValue));
				} catch (error) {
					console.error('Error decoding base64 parameter:', error);
				}
			}
		};

		getQueryParams();
	}, []);

	return (
		<div className={'App'}>
			{stockDetails &&
				<TVChartContainer stockDetails={stockDetails} />
			}
		</div>
	);
};

export default App;
